import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {HiArrowLeft} from 'react-icons/hi';
import {FaExchangeAlt} from 'react-icons/fa';
import {AiOutlineSound} from 'react-icons/ai';
import {UIVocabulary, UIWordPair} from "../ui-types/ui-types";

export const Learning: React.FC = () => {
    const location = useLocation();// Retrieve the merged dictionary from state
    const [currentWord, setCurrentWord] = useState<string>('');
    const [keyLanguage, setKeyLanguage] = useState<string>('');
    const [valueLanguage, setValueLanguage] = useState<string>('');
    const [history, setHistory] = useState<string[]>([]); // Array to keep track of word history
    const [usedWords, setUsedWords] = useState<Set<string>>(new Set<string>()); // Set to track used words
    const [showTranslation, setShowTranslation] = useState<boolean>(false);
    const [translation, setTranslation] = useState<string>('');
    const [isSwapped, setIsSwapped] = useState<boolean>(false); // State to track swap status
    const [dictionary, setDictionary] = useState<Map<string, string>>(new Map<string, string>()); // State to track swap status
    const [vocabulary, setVocabulary] = useState<UIVocabulary>(); // State to track swap status

    useEffect(() => {
        if (dictionary.size === 0) {
            setVocabulary(location.state.vocabulary);
            let voc: UIVocabulary = location.state.vocabulary;
            if (voc != null) {
                setKeyLanguage(voc.language1);
                setValueLanguage(voc.language2);
                let dict: Map<string, string> = new Map<string, string>();
                voc.dictionary.forEach(words => {
                    dict.set(words.k, words.v); // Use `id` as the key and `name` as the value
                });
                setDictionary(dict);
            }
        }
    }, [location.state]);

    useEffect(() => {
        getRandomWord();
    }, [dictionary]);

    const getRandomWord = () => {
        const words: string[] = Array.from(dictionary.keys());
        // Filter out already used words
        let availableWords = words.filter(word => usedWords !== undefined && !usedWords.has(word));
        if (availableWords.length === 0) {
            availableWords = words;
            // Reset used words for a new session
            setUsedWords(new Set());
            setHistory([]); // Optionally clear the history// Start over
        }

        const randomWord: string = availableWords[Math.floor(Math.random() * availableWords.length)];

        // Update history and used words
        if (currentWord !== '') {
            setHistory((prev) => [...prev, currentWord]);
        }
        setUsedWords(prev => new Set(prev).add(randomWord)); // Add the new word to usedWords

        setCurrentWord(randomWord);
        let translation: string | undefined = dictionary.get(randomWord);
        if (translation != null) {
            setTranslation(translation);
        }// Set translation
        setShowTranslation(false); // Reset translation visibility
    };

    const toggleTranslation = () => {
        if (showTranslation) {
            getRandomWord(); // Get a new random word if translation is currently displayed
        } else {
            setShowTranslation(true); // Show the translation
        }
    };

    const handleBack = () => {
        // If there are previous words in the history, go back to the last one
        if (history.length > 0) {
            const previousWord = history[history.length - 1]; // Get the last word in history
            const previousTranslation = dictionary.get(previousWord); // Get the corresponding translation

            // Update state with the previous word and remove it from history
            setCurrentWord(previousWord);
            if (previousTranslation != null) {
                setTranslation(previousTranslation);
            }
            setShowTranslation(false); // Reset translation visibility
            setHistory((prev) => prev.slice(0, -1)); // Remove the last word from history
        }
    };

    const handleSwap = () => {
        // Swap the key and value
        if (vocabulary != null) {
            setKeyLanguage(isSwapped ? vocabulary.language1 : vocabulary.language2);
            setValueLanguage(isSwapped ? vocabulary.language2 : vocabulary.language1);
        }
        setIsSwapped(!isSwapped);
        // Reset history and used words when swapping
        setHistory([]);
        setUsedWords(new Set());
        getRandomWord(); // Get a new word after swapping
    };

    function readWord() {
        let word: string = showTranslation ? (isSwapped ? currentWord : translation) : (isSwapped ? translation : currentWord);
        let language: string = showTranslation ? valueLanguage : keyLanguage;

        if (word != null && language != null) {
            const utterance: SpeechSynthesisUtterance = new SpeechSynthesisUtterance(word);
            const voices = window.speechSynthesis.getVoices();
            const selectedVoice: SpeechSynthesisVoice | undefined = voices.find(voice => voice.lang === language);
            utterance.lang = language;
            if (selectedVoice != null) {
                utterance.voice = selectedVoice;
            }
            window.speechSynthesis.speak(utterance);
        }
    };

    return (
        <div className="flex flex-col items-center h-[calc(100vh-200px)] justify-center relative">
            <button
                onClick={handleSwap}
                className="mb-4 p-2 rounded-full shadow-md bg-gray-600 text-white hover:bg-gray-700 transition duration-200 flex items-center"
                title="Swap Words"
            >
                <FaExchangeAlt className="w-5 h-5"/>
            </button>
            <div
                className="cursor-pointer bg-gray-600 text-white p-6 rounded-full shadow-lg text-xl transition-all duration-200 flex items-center justify-center select-none"
                onClick={toggleTranslation}
            >
                {showTranslation ? (isSwapped ? currentWord : translation) : (isSwapped ? translation : currentWord)}
            </div>
            <button
                onClick={readWord}
                className="mt-4 p-2 rounded-full shadow-md bg-gray-600 text-white hover:bg-gray-700 transition duration-200 flex items-center"
                title="Speak"
            >
                <AiOutlineSound className="w-5 h-5"/>
            </button>
            <button
                onClick={handleBack}
                disabled={history.length === 0} // Disable the button if there are no previous words
                className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 rounded-full shadow-md transition duration-200 flex items-center ${
                    history.length > 0
                        ? 'bg-gray-600 text-white hover:bg-gray-700'
                        : 'bg-gray-400 text-gray-200 cursor-not-allowed'
                }`}
                title="Go Back"
            >
                <HiArrowLeft className="w-5 h-5"/>
            </button>
        </div>
    );
};
