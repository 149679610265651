import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';
import {API_GATEWAY} from "../App";
import {FiEye} from "react-icons/fi";
import {MdPlayArrow} from "react-icons/md";
import {compareWordsWithTypo} from "../util/lang";
import {FaPlay, FaQuestion} from "react-icons/fa";
import {useUserSettings} from "../components/UserSettingsContext";
import {UIQuestion} from "../ui-types/ui-types";
import WordPopupModal from "../components/WordPopupModal";
import GrammarExplanationPopupModal from "../components/GrammarExplanationPopupModal";


const QuizGame: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Get selected topics from the QuizDetails page
    const {selectedTopics, type} = location.state as { selectedTopics: string[], type: string };
    const {language, interfaceLanguage} = useUserSettings();
    const [questions, setQuestions] = useState<UIQuestion[]>([]);
    const [currentQuestion, setCurrentQuestion] = useState<UIQuestion | null>(null);
    const [answer, setAnswer] = useState<string>('');
    const [validationResult, setValidationResult] = useState<string>('');
    const [showCorrectAnswer, setShowCorrectAnswer] = useState<boolean>(false);
    const [askedQuestions, setAskedQuestions] = useState<Set<number>>(new Set());
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [animationClass, setAnimationClass] = useState<string>('');
    const initialized = useRef(false);
    const [correctAnswer, setCorrectAnswer] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalWord, setModalWord] = useState<string>('');
    const [modalPosition, setModalPosition] = useState<{ x: number; y: number } | null>(null);

    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true
        const fetchQuestions = async () => {
            try {
                // Fetch questions based on selected topic IDs
                const response = await axios.get(API_GATEWAY + `/quiz?type=${type}&ids=${selectedTopics.join(',')}`);
                setQuestions(response.data.questions);
                selectRandomQuestion(response.data.questions, new Set());
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };
        fetchQuestions();
    }, []);

    const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer(e.target.value);
    };

    const validateAnswer = (userAnswer: string) => {
        if (currentQuestion == null || compareWordsWithTypo(userAnswer.trim(), currentQuestion.correctAnswer)) {
            setValidationResult('Correct!');
        } else {
            setValidationResult('Incorrect. Try again!');
        }
        setIsValidated(true); // Trigger the animation after validation
        setAnimationClass('animate-bounce');
    };


    const handleSubmitAnswer = () => {
        validateAnswer(answer);
    };

    const selectRandomQuestion = (questions: UIQuestion[], usedIndices: Set<number>) => {
        if (usedIndices.size >= questions.length) {
            navigate('/quiz-completed');
            return;
        }

        let randomIndex: number;
        do {
            randomIndex = Math.floor(Math.random() * questions.length);
        } while (usedIndices.has(randomIndex));

        usedIndices.add(randomIndex);
        let newQuestion: UIQuestion = questions[randomIndex];
        setCurrentQuestion(newQuestion);
        setAskedQuestions(new Set(usedIndices)); // update the state with the new set
        setValidationResult('');
        setShowCorrectAnswer(false);
        setIsValidated(false);
        setAnimationClass('');
        setAnswer('');
        setCorrectAnswer(newQuestion != null ? newQuestion.correctAnswer : '');
        if (type === 'audio') {
            readWord(newQuestion.correctAnswer);
        }
    };

    function readWord(word: string) {
        if (word != null && language != null) {
            let soundLanguage = 'US English Female';
            switch (language) {
                case 'en': {
                    soundLanguage = 'US English Female';
                    break;
                }
                case 'de': {
                    soundLanguage = 'German Female';
                    break;
                }
                case 'pt': {
                    soundLanguage = 'Brazilian Portuguese Female';
                    break;
                }
                case 'es': {
                    soundLanguage = 'Spanish Latin American Female';
                    break;
                }
                case 'ru': {
                    soundLanguage = 'Russian Female';
                    break;
                }
            }
            window.responsiveVoice.speak(word, soundLanguage);
        }
    };

    function createGrammarQuestion(text: string, wordToReplace: string): string {
        if (text == null) {
            return text;
        }
        return text.replace(new RegExp(`\\b${wordToReplace}\\b`, 'g'), "_____");

    };

    const handleNextQuestion = () => {
        selectRandomQuestion(questions, askedQuestions);
    };

    const revealAnswer = () => {
        setShowCorrectAnswer(true);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission
            if (validationResult === 'Correct!') {
                handleNextQuestion();
            } else {
                return handleSubmitAnswer();
            }
        }
        if (event.key === 'Control') {
            event.preventDefault(); // Prevent the default form submission
            revealAnswer();
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalWord('');
        setModalPosition(null);
    };

    return (
        <div className="flex flex-col items-center p-4 space-y-4">
            <GrammarExplanationPopupModal
                text={currentQuestion != null ? currentQuestion.text : ''}
                word={currentQuestion != null ? currentQuestion.correctAnswer : ''}
                isOpen={isModalOpen}
                onClose={closeModal}
            />
            {currentQuestion && (
                <>
                    {type !== 'audio' ? (
                        <div className="flex justify-center w-full max-w-md mb-4 space-x-4">
                            <span
                                className="text-xl font-semibold text-gray-800">
                                {type === 'grammar' ? createGrammarQuestion(currentQuestion.text, currentQuestion.correctAnswer) : currentQuestion.text}
                            </span>
                            <button
                                onClick={revealAnswer}
                                className="p-2 bg-blue-500 text-white rounded-full"
                            >
                                <FiEye size={15}/>
                            </button>
                            {type === 'grammar' ? (<button
                                onClick={() => setIsModalOpen(true)}
                                className="p-2 bg-amber-500 text-white rounded-full"
                            >
                                <FaQuestion size={15}/>
                            </button>) : (<div></div>)}
                        </div>) : (
                        <div className="flex justify-center items-center w-full max-w-md mb-4 space-x-4">
                            <button
                                className="bg-blue-600 hover:bg-blue-700 text-white font-bold p-4 rounded-md flex justify-center items-center"
                                aria-label="Play"
                                onClick={() => readWord(currentQuestion?.correctAnswer)}
                            >
                                <FaPlay className="text-xl"/>
                            </button>
                            <button
                                onClick={revealAnswer}
                                className="bg-blue-500 font-bold w-8 h-8 text-white rounded-full flex justify-center items-center"
                            >
                                <FiEye size={15}/>
                            </button>
                        </div>)}

                    <p className={`text-lg font-medium text-center mb-4 h-6 ${validationResult ? 'text-gray-700' : 'text-transparent'} ${animationClass}`}
                    >
                        {validationResult ?? 'Validation Placeholder'}
                    </p>

                    <p className={`text-md font-medium text-center mb-4 h-6 ${showCorrectAnswer ? 'text-green-600' : 'text-transparent'}`}>
                        {showCorrectAnswer ? `Correct Answer: ${correctAnswer}` : 'Correct Answer Placeholder'}
                    </p>

                    {type === 'written' ? (
                        <div className="w-full max-w-md">
                            <div className="flex items-center space-x-2 mb-4">
                                <input
                                    autoFocus
                                    type="text"
                                    value={answer}
                                    onChange={handleAnswerChange}
                                    onKeyDown={handleKeyDown}
                                    className="flex-1 px-4 py-2 border rounded"
                                    placeholder="Type your answer"
                                />
                                <button
                                    onClick={handleSubmitAnswer}
                                    className="p-2 bg-indigo-500 text-white rounded-full"
                                >
                                    <MdPlayArrow size={20}/>
                                </button>
                            </div>
                        </div>
                    ) : type === 'test' || type === 'grammar' ? (
                        <div className="flex flex-col gap-4 mb-4">
                            {currentQuestion.answers.map((answerOption) => (
                                <button
                                    key={answerOption.text}
                                    className="px-4 py-2 rounded-full border-2 bg-white text-blue-500"
                                    onClick={() => validateAnswer(answerOption.text)}
                                >
                                    {answerOption.text}
                                </button>
                            ))}
                        </div>
                    ) : (
                        <div className="w-full max-w-md">
                            <div className="flex items-center space-x-2 mb-4">
                                <input
                                    autoFocus
                                    type="text"
                                    value={answer}
                                    onChange={handleAnswerChange}
                                    onKeyDown={handleKeyDown}
                                    className="flex-1 px-4 py-2 border rounded"
                                    placeholder="Type your answer"
                                />
                                <button
                                    onClick={handleSubmitAnswer}
                                    className="p-2 bg-indigo-500 text-white rounded-full"
                                >
                                    <MdPlayArrow size={20}/>
                                </button>
                            </div>
                        </div>
                    )}
                    <button
                        onClick={handleNextQuestion}
                        className="py-2 px-6 bg-green-500 text-white rounded-md sm:mt-4"
                    >
                        Next
                    </button>

                </>
            )}
        </div>
    );
};

export default QuizGame;
