import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ExplanationDisplayProps {
    explanation: string;
}

const ExplanationDisplay: React.FC<ExplanationDisplayProps> = ({ explanation }) => {


    return (
        <div className="markdown-container mt-4 p-4 border border-gray-300 rounded bg-gray-50 w-full md:w-3/4 lg:w-2/3">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {explanation}
            </ReactMarkdown>
        </div>
    );
};

export default ExplanationDisplay;
