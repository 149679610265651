// src/types.ts
export interface UIVocabulary {
    vocabulary_id: string;
    name: string;
    dictionary: UIWordPair[];
    language1: string;
    language2: string;
}

export interface UIWordPair {
    k: string;
    v: string;
    c: string;
}

export interface UIStory {
    story_id: string;
    name: string;
    language: string;
    author: string;
    text: string;
    level: string;
}

export interface UIQuiz {
    quiz_id: string,
    name: string,
    language: string,
    type: string;
    questions: UIQuestion[];
}

export interface UIQuestion {
    text: string;
    answers: UIAnswer[];
    category: string;
    topic: string;
    correctAnswer: string;
}

export interface UIAnswer {
    text: string;
    correct: boolean;
}

export interface UserSettings {
    language: string;
    interfaceLanguage: string;
    setLanguage: (lang: string) => void;
    setInterfaceLanguage: (lang: string) => void;
}

export const SupportedLanguages = [
    {code: 'pt', name: 'Portuguese', flag: 'https://flagcdn.com/br.svg'},
    {code: 'en', name: 'English', flag: 'https://flagcdn.com/us.svg'},
    {code: 'es', name: 'Spanish', flag: 'https://flagcdn.com/es.svg'},
    {code: 'ru', name: 'Ukrainian', flag: 'https://flagcdn.com/ua.svg'},
    {code: 'de', name: 'German', flag: 'https://flagcdn.com/de.svg'},
];

export const getFlagLink = (code: string): string | undefined => {
    let filtered = SupportedLanguages.find(lang => lang.code === code) || SupportedLanguages[0];
    return filtered.flag;
};

export const levels: string[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

declare global {
    interface Window {
        responsiveVoice: {
            speak: (text: string, voice: string, options?: object) => void;
            pause: () => void;
            resume: () => void;
            cancel: () => void;
            isPlaying: () => boolean;
            setDefaultVoice: (voice: string) => void;
            getVoices: () => string[];
        };
    }
}