import React from 'react';


interface VocabularyWidgetProps {
    badge: {
        vocabulary_id: string;
        name: string;
    };
    isSelected: boolean;
    onClick: () => void; // Function type for the click handler
}

const VocabularyWidget: React.FC<VocabularyWidgetProps> = ({badge, isSelected, onClick}) => {
    return (
        <div
            onClick={onClick}
            className={`cursor-pointer p-4 rounded-full transition duration-200 
        ${isSelected ? 'bg-gray-600 border-gray-600 text-white' : 'bg-gray-200 border-gray-400 text-gray-800'}
         shadow-md hover:shadow-lg hover:scale-105`}
            style={{fontFamily: 'Arial, sans-serif', fontWeight: '600'}} // Use a clean font
        >
            {badge.name}
        </div>
    );
};

export default VocabularyWidget;
