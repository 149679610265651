import React, {useState} from 'react';
import ExplanationDisplay from "../components/ExplanationDisplay";
import axios from "axios";
import {API_GATEWAY} from "../App";
import {useUserSettings} from "../components/UserSettingsContext";
import {MdPlayArrow} from "react-icons/md";

export const ExplainTab: React.FC = () => {
    const [query, setQuery] = useState('');
    const [explanation, setExplanation] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const {language, interfaceLanguage} = useUserSettings();
    const [wordLanguage, setWordLanguage] = useState<string>(language);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
        setExplanation(null); // Reset explanation on new input
        setError(null); // Reset error
    };

    const handleSearch = async () => {
        if (!query) return;
        setLoading(true);
        try {
            const response = await axios.get(API_GATEWAY + `/explain?word=${encodeURIComponent(query)}&language=${wordLanguage}&interfaceLanguage=${interfaceLanguage}`);
            setExplanation(response.data.explanation);
            setError(null);
        } catch (err) {
            setError('Failed to fetch explanation. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission
            return handleSubmit();
        }
    };

    const handleSubmit = () => {
        return handleSearch();
    };

    return (
        <div className="flex flex-col items-center p-4">
            <h1 className="text-2xl font-semibold mb-6 text-gray-800">Explain</h1>
            <div className="flex gap-2 items-center">
                {/* Adjust the input width on small screens and allow it to grow on larger screens */}
                <input
                    type="text"
                    autoFocus
                    value={query}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter a word ..."
                    className="border border-gray-300 rounded p-2 flex-grow min-w-[250px] sm:min-w-[300px] md:w-3/4" // Added flex-grow
                />

                <button
                    onClick={handleSubmit}
                    className="bg-gray-800 text-white rounded-full px-2 py-2 hover:bg-gray-600 disabled:opacity-50"
                    disabled={loading}
                >
                    <MdPlayArrow  size={20}/>
                </button>
            </div>

            {error && <p className="text-red-500 mt-4">{error}</p>}
            {explanation && <ExplanationDisplay explanation={explanation}/>}
        </div>
    );
};
