import React from 'react';
import { useNavigate } from 'react-router-dom';

const QuizTab: React.FC = () => {
    const navigate = useNavigate();

    const handleQuizSelection = (type: 'written' | 'test' | 'audio' | 'grammar') => {
        navigate(`/quiz/${type}`);
    };

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <h1 className="text-2xl font-semibold mb-6 text-gray-800">Choose Quiz</h1>
            <div className="w-full max-w-md">
                <button
                    className="w-full py-4 mb-4 text-lg font-medium text-white bg-blue-500 rounded-md shadow-md hover:bg-blue-600 focus:outline-none"
                    onClick={() => handleQuizSelection('written')}
                >
                    Written Quiz
                </button>
                <button
                    className="w-full py-4 mb-4 text-lg font-medium text-white bg-green-500 rounded-md shadow-md hover:bg-green-600 focus:outline-none"
                    onClick={() => handleQuizSelection('test')}
                >
                    Test Quiz
                </button>
                <button
                    className="w-full py-4 mb-4 text-lg font-medium text-white bg-yellow-500 rounded-md shadow-md hover:bg-yellow-600 focus:outline-none"
                    onClick={() => handleQuizSelection('audio')}
                >
                    Audio Quiz
                </button>
                <button
                    className="w-full py-4 mb-4 text-lg font-medium text-white bg-purple-500 rounded-md shadow-md hover:bg-purple-600 focus:outline-none"
                    onClick={() => handleQuizSelection('grammar')}
                >
                    Grammar Quiz
                </button>
            </div>
        </div>
    );
};

export default QuizTab;
