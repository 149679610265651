// SettingsModal.tsx
import React, {useState} from 'react';
import {useUserSettings} from './UserSettingsContext';
import LanguageSelector from "./LanguageSelector";

interface SettingsModalProps {
    onClose: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({onClose}) => {
    const {language, interfaceLanguage, setLanguage, setInterfaceLanguage} = useUserSettings();
    const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
    const [selectedInterfaceLanguage, setSelectedInterfaceLanguage] = useState<string>(interfaceLanguage);

    const handleLanguageSelection = (lang: string) => {
        setLanguage(lang);
    };

    const handleInterfaceLanguageSelection = (lang: string) => {
        setInterfaceLanguage(lang);
    };

    const closeModal = () => {
        onClose();
        window.location.reload();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm text-gray-800">
                <h2 className="text-xl font-semibold mb-4 text-center">Settings</h2>
                <label className="block mb-4 text-center">
                    <span className="text-gray-700">Language:</span>
                    <LanguageSelector defaultLanguage={selectedLanguage} onLanguageChange={handleLanguageSelection}/>
                </label>

                <label className="block mb-4 text-center">
                    <span className="text-gray-700">Interface:</span>
                    <LanguageSelector defaultLanguage={selectedInterfaceLanguage}
                                      onLanguageChange={handleInterfaceLanguageSelection}/>
                </label>

                <button
                    onClick={closeModal}
                    className="w-full py-2 bg-gray-500 text-white rounded-lg font-semibold hover:bg-gray-600"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default SettingsModal;
