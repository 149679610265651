export function levenshtein(a: string, b: string): number {
    const tmp: number[][] = [];
    for (let i = 0; i <= a.length; i++) {
        tmp[i] = [i];
    }
    for (let j = 0; j <= b.length; j++) {
        tmp[0][j] = j;
    }
    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
            const cost = a[i - 1] === b[j - 1] ? 0 : 1;
            tmp[i][j] = Math.min(
                tmp[i - 1][j] + 1, // Deletion
                tmp[i][j - 1] + 1, // Insertion
                tmp[i - 1][j - 1] + cost // Substitution
            );
        }
    }
    return tmp[a.length][b.length];
}

export function compareWordsWithTypo(word1: string, word2: string): boolean {
    // Normalize both words to remove diacritics and convert to lowercase
    const normalizedWord1 = word1
        .normalize('NFD') // Decompose characters into base characters and diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
        .toLowerCase(); // Convert to lowercase

    const normalizedWord2 = word2
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

    // Check if words are the same (exact match) or allow for one typo (Levenshtein distance <= 1)
    const distance = levenshtein(normalizedWord1, normalizedWord2);
    return distance <= 1;
}
