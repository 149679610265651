import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {API_GATEWAY} from "../App";
import {levels, UIStory} from "../ui-types/ui-types";
import {FaBookOpen} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useUserSettings} from "../components/UserSettingsContext";
import {HiChevronLeft, HiChevronRight} from 'react-icons/hi';

export const ReadTab: React.FC = () => {
    const [stories, setStories] = useState<UIStory[]>([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const {language, interfaceLanguage} = useUserSettings();
    const [keyword, setKeyword] = useState('');
    const [debouncedKeysword, setDebouncedKeysword] = useState("");
    const [level, setLevel] = useState<string>("*");
    const limit = 10;
    const initialized = useRef(false);

    useEffect(() => {
        initialized.current = true
        const fetchStories = async () => {
            const response = await axios.get(API_GATEWAY +
                `/stories?page=${page}&limit=${limit}&language=${language}&keyword=${debouncedKeysword}&level=${level}`
            );
            setStories(response.data.stories);
            setTotal(response.data.total);
        };

        fetchStories();
    }, [page, language, level, debouncedKeysword]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedKeysword(keyword);
        }, 300); // 300ms delay (adjust based on preference)

        return () => clearTimeout(timer); // Cleanup previous timer
    }, [keyword]);

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
        setPage(1); // Reset to first page on filter change
    };

    const handleNextPage = () => {
        if (page * limit < total) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const navigate = useNavigate();

    const handleRowClick = (storyId: string) => {
        // Navigate to the specific story page on small screens
        navigate(`/stories/${storyId}`);
    };

    const handleIconClick = (storyId: string, event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent triggering row click
        navigate(`/stories/${storyId}`);
    };


    return (
        <div className="mx-auto max-w-screen-xl px-4 lg:px-12 space-y-1 mt-1">
            <div className="bg-white dark:bg-gray-800 relative sm:rounded-lg overflow-hidden">
                <div
                    className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-1/2">
                        <label htmlFor="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                     fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                          clipRule="evenodd"/>
                                </svg>
                            </div>
                            <input
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                type="text"
                                value={keyword}
                                onChange={handleKeywordChange}
                                placeholder="Filter by name or author"/>
                        </div>
                    </div>
                    <div
                        className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                        <div className="flex items-center space-x-3 w-full md:w-auto">
                            <select
                                id="level-filter"
                                value={level || ''}
                                onChange={(e) => setLevel(e.target.value || '*')}
                                className="block p-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200"
                            >
                                <option value="*">All Levels</option>
                                {levels.map((level) => (
                                    <option key={level} value={level}>
                                        {level}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <table className="min-w-full bg-white ">
                {/* Table Header */}
                <thead className="hidden sm:table-header-group">
                <tr>
                    <th className="md:py-6 md:px-4 text-left border border-b">Name</th>
                    <th className="md:py-6 md:px-4 text-left border border-b">Author</th>
                    <th className="md:py-6 md:px-4 text-left border border-b">Level</th>
                    <th className="md:py-6 md:px-4 text-left border border-b">Read</th>
                </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                {stories.map((story) => (
                    <tr
                        key={story.story_id}
                        className="border-b-2 sm:table-row cursor-pointer"
                        onClick={(e) => {
                            if (window.innerWidth <= 800) {
                                handleRowClick(story.story_id);
                            }
                        }}
                    >
                        <td className="md:py-6 md:px-4 sm:border-b sm:border sm:table-cell flex flex-wrap sm:flex-nowrap sm:items-center">
                            {/* For small screens, label the data */}
                            <div className="sm:hidden font-semibold mr-7">Name:</div>
                            <span>{story.name}</span>
                        </td>
                        <td className="md:py-6 md:px-4 sm:border-b sm:border sm:table-cell flex flex-wrap sm:flex-nowrap sm:items-center">
                            {/* For small screens, label the data */}
                            <div className="sm:hidden font-semibold mr-7">Author:</div>
                            <span>{story.author}</span>
                        </td>
                        <td className="md:py-6 md:px-4 sm:border-b sm:border sm:table-cell flex flex-wrap sm:flex-nowrap sm:items-center">
                            {/* For small screens, label the data */}
                            <div className="sm:hidden font-semibold mr-7">Level:</div>
                            <span>{story.level}</span>
                        </td>
                        <td className="w-auto md:px-6 md:py-4 whitespace-nowrap sm:border-b sm:border sm:table-cell flex flex-wrap sm:flex-nowrap sm:items-center">
                            {/* On large screens, show the icon and hide the row click */}
                            <div className="hidden sm:block">
                                <button
                                    className="text-gray-600 hover:text-gray-800"
                                    onClick={(e) => handleIconClick(story.story_id, e)} // Icon click for large screens
                                >
                                    <FaBookOpen className="w-5 h-5"/>
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>


            <div className="flex justify-between items-center mt-4">
                <button
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
                >
                    <HiChevronLeft className="text-xl"/>
                </button>

                <span>
          Page {page} of {Math.ceil(total / limit)}
        </span>

                <button
                    onClick={handleNextPage}
                    disabled={page * limit >= total}
                    className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
                >
                    <HiChevronRight className="text-xl"/>
                </button>
            </div>
        </div>
    );
};

