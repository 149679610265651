import React, {useEffect, useRef, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {UIQuiz, UIVocabulary} from "../ui-types/ui-types";
import axios from "axios";
import {API_GATEWAY} from "../App";
import {useUserSettings} from "../components/UserSettingsContext";

const QuizDetails: React.FC = () => {
    const {type} = useParams<{ type: 'written' | 'test' | 'audio' | 'grammar' }>();
    const [topics, setTopics] = useState<UIQuiz[]>([]);
    const navigate = useNavigate();
    const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
    const initialized = useRef(false);
    const {language, interfaceLanguage} = useUserSettings();

    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true
        const fetchAllTopics = async () => {
            try {
                const response
                    = await axios.get(API_GATEWAY + '/quizzes?language=' + language +'&type=' + type);
                setTopics(response.data); // Set the fetched topics
            } catch (error) {
                console.error('Error fetching topics:', error);
            }
        };
        fetchAllTopics();
    }, []);

    const handleTopicSelection = (id: string) => {
        setSelectedTopics((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((topicId) => topicId !== id)
                : [...prevSelected, id]
        );
    };

    const handleStartQuiz = () => {
        if (selectedTopics.length > 0) {
            navigate('/quiz/game', {state: {selectedTopics, type}});
        } else {
            alert('Please select at least one topic to start.');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <h1 className="text-2xl font-semibold text-gray-800 mb-4">Select Quiz Topics</h1>
            <div className="flex flex-wrap gap-4 mb-4">
                {topics.map((topic) => (
                    <button
                        key={topic.quiz_id}
                        className={`px-4 py-2 rounded-full border-2 ${selectedTopics.includes(String(topic.quiz_id)) ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}
                        onClick={() => handleTopicSelection(String(topic.quiz_id))}
                    >
                        {topic.name}
                    </button>
                ))}
            </div>

            <button
                className="py-2 px-6 bg-indigo-500 text-white rounded-md mt-4"
                onClick={handleStartQuiz}
                disabled={selectedTopics.length === 0}
            >
                Start Quiz
            </button>
        </div>
    );
};

export default QuizDetails;
