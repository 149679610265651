import React from 'react';
import {BrowserRouter as Router, Route, Routes, NavLink} from 'react-router-dom';
import {ExplainTab} from "./tabs/ExplainTab";
import {Learning} from "../../client/src/tabs/Learning";
import {LearnTab} from "../../client/src/tabs/LearnTab";
import {ReadTab} from "./tabs/ReadTab";
import StoryTab from "./tabs/StoryTab";
import {UserSettingsProvider} from "./components/UserSettingsContext";
import AppHeader from "./components/AppHeader";
import QuizTab from "./tabs/QuizTab";
import QuizDetails from "./tabs/QuizDetails";
import QuizGame from "./tabs/QuizGame";

export const API_GATEWAY: string = process.env.REACT_APP_API_URL || 'http://localhost:5001';


function App() {
    return (<Router>
            <div className="flex flex-col h-screen">
                <UserSettingsProvider>
                    <AppHeader/>
                    {/* Other app components here that can use `useUserSettings` to access settings */}
                    <nav className="bg-white border-b border-gray-300 shadow-sm">
                        <div className="flex justify-around">
                            <NavLink
                                to="/"
                                className={({isActive}) =>
                                    `py-3 px-4 block text-center transition duration-300 ${isActive ? "text-gray-800 border-b-2 border-gray-800" : "text-gray-600 hover:text-gray-600"}`
                                }
                            >
                                Discover
                            </NavLink>
                            <NavLink
                                to="/read"
                                className={({isActive}) =>
                                    `py-3 px-4 block text-center transition duration-300 ${isActive ? "text-gray-800 border-b-2 border-gray-800" : "text-gray-600 hover:text-gray-600"}`
                                }
                            >
                                Read
                            </NavLink>
                            <NavLink
                                to="/quiz"
                                className={({isActive}) =>
                                    `py-3 px-4 block text-center transition duration-300 ${isActive ? "text-gray-800 border-b-2 border-gray-800" : "text-gray-600 hover:text-gray-600"}`
                                }
                            >
                                Quiz
                            </NavLink>
                        </div>
                    </nav>

                    <main className="flex-grow flex-col justify-between relative">
                        <Routes>
                            <Route path="/" element={<ExplainTab/>}/>
                            <Route path="/learn" element={<LearnTab/>}/>
                            <Route path="/learning" element={<Learning/>}/>
                            <Route path="/read" element={<ReadTab/>}/>
                            <Route path="/stories/:id" element={<StoryTab/>}/>
                            <Route path="/quiz" element={<QuizTab />}/>
                            <Route path="/quiz/:type" element={<QuizDetails/>}/>
                            <Route path="/quiz/game" element={<QuizGame/>}/>
                        </Routes>
                    </main>

                    <footer className="bg-white border-t border-gray-300 text-center py-2">
                        <p className="text-gray-600">© 2024 Learning words app</p>
                    </footer>
                </UserSettingsProvider>
            </div>
        </Router>
    );
}

export default App;
