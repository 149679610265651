// UserSettingsContext.tsx
import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {UserSettings} from "../ui-types/ui-types";

const UserSettingsContext = createContext<UserSettings | undefined>(undefined);

interface UserSettingsProviderProps {
    children: ReactNode;
}

export const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({ children }) => {
    const [language, setLanguage] = useState<string>(() => localStorage.getItem('language') || 'pt');
    const [interfaceLanguage, setInterfaceLanguage] = useState<string>(() => localStorage.getItem('interfaceLanguage') || 'en');

    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    useEffect(() => {
        localStorage.setItem('interfaceLanguage', interfaceLanguage);
    }, [interfaceLanguage]);

    return (
        <UserSettingsContext.Provider value={{ language, interfaceLanguage, setLanguage, setInterfaceLanguage }}>
            {children}
        </UserSettingsContext.Provider>
    );
};

export const useUserSettings = () => {
    const context = useContext(UserSettingsContext);
    if (!context) throw new Error("useUserSettings must be used within a UserSettingsProvider");
    return context;
};
