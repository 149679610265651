import {UIStory, UIVocabulary} from "../ui-types/ui-types";


export const newBadges: UIVocabulary[] = [
    {
        vocabulary_id: 'test',
        name: 'Food Vocabulary',
        dictionary: [
            {k: 'bread', v: 'pan', c: 'noun'},
            {k: 'cheese', v: 'queso', c: 'noun'},
            {k: 'chicken', v: 'pollo', c: 'noun'},
            {k: 'fish', v: 'pescado', c: 'noun'},
        ],
        language1: 'ru-RU',
        language2: 'pt-BR'
    },
];

export const mockExplanation = {
    text: `A palavra **"garota"** em português refere-se a uma jovem mulher ou menina, geralmente usada de forma carinhosa ou informal. O termo é frequentemente utilizado para se referir a adolescentes ou jovens adultas, mas pode ser usado em contextos mais amplos. Em algumas regiões, pode também ser uma forma de se referir a uma "namorada" ou "amiga".

### Exemplos de Uso:

1. **Referindo-se a uma jovem:**
   - *A garota estava sorrindo enquanto caminhava pelo parque.*
   *(The girl was smiling while walking through the park.)*

2. **Em um contexto de amizade:**
   - *A garota que conheci na festa é muito divertida.*
   *(The girl I met at the party is very fun.)*

3. **Referindo-se a uma namorada:**
   - *Ele trouxe a garota para conhecer seus pais.*
   *(He brought the girl to meet his parents.)*

4. **Descrevendo características:**
   - *Ela é uma garota inteligente e cheia de sonhos.*
   *(She is an intelligent girl full of dreams.)*

5. **Em uma conversa informal:**
   - *Aquela garota canta muito bem!*
   *(That girl sings really well!)*

### Notas Culturais:

- O termo "garota" é amplamente usado no Brasil, enquanto em Portugal, é mais comum o uso de "rapariga" para se referir a uma jovem mulher, embora "garota" também seja entendido.
- Em contextos informais, pode ser usada em expressões como "garota de programa," que se refere a uma mulher que oferece serviços sexuais, mas é importante ter cuidado com o contexto, pois pode ser considerado ofensivo.

Se precisar de mais informações ou exemplos, é só avisar!`

};
