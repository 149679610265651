import React, {useEffect, useState} from 'react';
import {SupportedLanguages} from "../ui-types/ui-types";

interface Language {
    code: string;
    name: string;
    flag: string;
}

interface LanguageSelectorProps {
    defaultLanguage?: string;
    onLanguageChange: (language: string) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({defaultLanguage, onLanguageChange}) => {


    const defaultLang = SupportedLanguages.find(lang => lang.code === defaultLanguage) || SupportedLanguages[0]; // Fallback to English if not found

    const [selectedLanguage, setSelectedLanguage] = useState<Language>(defaultLang);

    useEffect(() => {
        onLanguageChange(selectedLanguage.code);
    }, [selectedLanguage]);

    return (
        <div className="flex flex-col items-center space-y-6">
            <div className="relative">
                <select
                    onChange={(e) => setSelectedLanguage(SupportedLanguages.find(lang => lang.code === e.target.value) || defaultLang)}
                    className="border rounded-md p-2 pr-8 appearance-none bg-white"
                    value={selectedLanguage.code}
                >
                    {SupportedLanguages.map((language) => (
                        <option key={language.code} value={language.code}>
                            {language.code} {/* Display only the language code */}
                        </option>
                    ))}
                </select>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <img
                src={selectedLanguage?.flag}
                alt={selectedLanguage?.code}
                className="h-5 w-5"
            />
          </span></div>

        </div>
    );
};

export default LanguageSelector;
