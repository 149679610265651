import React, {useState, useEffect, ChangeEvent, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import {newBadges} from "../mocks/mock";
import {AiOutlinePlus} from 'react-icons/ai';
import {UIVocabulary, UIWordPair} from "../ui-types/ui-types";
import axios from 'axios';
import {API_GATEWAY} from "../App";
import VocabularyWidget from "../components/VocabularyWidget";


export const LearnTab: React.FC = () => {
    const [badges, setBadges] = useState<UIVocabulary[]>([]);
    const [selectedBadges, setSelectedBadges] = useState<string[]>([]);
    const [newVocabulary, setNewVocabulary] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isInputVisible, setInputVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const initialized = useRef(false);

    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true
        // Fetch badges from the backend
        const fetchBadges = async () => {
            try {
                const response = await axios.get<UIVocabulary[]>(API_GATEWAY + '/vocabularies');
                setBadges(response.data);
            } catch (error) {
                console.error('Error fetching badges:', error);
            }
        };

        fetchBadges();
    }, []);

    const toggleBadgeSelection = (badge: string) => {
        if (selectedBadges.includes(badge)) {
            setSelectedBadges(selectedBadges.filter((b) => b !== badge)); // Deselect badge
        } else {
            setSelectedBadges([...selectedBadges, badge]); // Select badge
        }
    };

    const startLearning = () => {
        if (selectedBadges.length !== 0) {
            // Merge dictionaries from selected badges
            let mergedDictionary: UIWordPair[] = [];
            let selected = badges.filter((b) => selectedBadges.includes(b.name));
            selected.forEach(sv => {
                mergedDictionary = mergedDictionary.concat(sv.dictionary);
            });
            //create a pseudo-vocabulary
            const mergedVoc: UIVocabulary = {
                vocabulary_id: '1',
                name: 'Basic Vocabulary',
                dictionary: mergedDictionary,
                language1: selected[0].language1,
                language2: selected[0].language2
            }

            // Navigate to the learning view with the merged dictionary
            navigate('/learning', {state: {vocabulary: mergedVoc}});
        }
    };

    const handleCreateVocabulary = async () => {
        if (newVocabulary.trim() === '') return; // Prevent empty submissions
        setLoading(true);

        try {
            // Call the backend to create the new vocabulary
            // const createdBadge = await createVocabulary(newVocabulary);
            await new Promise(resolve => setTimeout(resolve, 5000));
            let createdBadge: UIVocabulary = newBadges[0];
            setBadges((prevBadges) => [...prevBadges, createdBadge]); // Add the new badge to the list
            setNewVocabulary(''); // Clear the input field
            setInputVisible(false);
        } catch (error) {
            console.error('Error creating vocabulary:', error);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement; // Use type assertion
        setNewVocabulary(target.value); // Now TypeScript knows target is an HTMLInputElement
    };

    return (
        <div className="flex flex-col items-center">
            <div className="flex flex-wrap justify-center mb-4">
                {badges.map((badge) => (
                    <div key={badge.vocabulary_id} className="m-2 select-none">
                        <VocabularyWidget
                            badge={badge}
                            isSelected={selectedBadges.includes(badge.name)}
                            onClick={() => toggleBadgeSelection(badge.name)}
                        />
                    </div>
                ))}
            </div>
            {/* Toggle input visibility */}
            {isInputVisible && (
                <div className="flex flex-col items-center mb-4">
                    <input
                        type="text"
                        value={newVocabulary}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                        placeholder="Enter new vocabulary"
                        className="border border-gray-400 rounded p-2 mb-2 w-64"
                        disabled={loading} // Disable input while loading
                    />
                    <button
                        onClick={handleCreateVocabulary}
                        className={`bg-gray-600 text-white p-2 rounded shadow-md hover:bg-gray-700 transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading} // Disable button while loading
                    >
                        {loading ? 'Creating...' : 'Create Vocabulary'}
                    </button>
                </div>
            )}

            <div className="absolute bottom-4 flex justify-between w-full px-4 mt-auto mb-4">
                {/* Button to toggle input visibility with React Icons */}
                <button
                    onClick={() => startLearning()} // Replace with your start learning logic
                    className="bg-gray-600 text-white p-4 rounded shadow-md hover:bg-gray-700 transition duration-200 flex-1 mr-2" // Flex-grow to take remaining space
                    disabled={selectedBadges.length === 0}
                >
                    Start Learning
                </button>

                {/* Button to toggle input visibility with React Icons */}
                <div
                    onClick={() => setInputVisible((prev) => !prev)} // Toggle input visibility
                    className="bg-green-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition duration-200 cursor-pointer flex items-center justify-center"
                    style={{width: '50px', height: '50px'}} // Make it circular
                >
                    <AiOutlinePlus size={24}/> {/* Render the plus icon */}
                </div>
            </div>
        </div>
    );
};
