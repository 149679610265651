import React, {useState} from "react";
import SettingsModal from "./SettingsModal";
import {FiSettings} from "react-icons/fi";
import {getFlagLink} from "../ui-types/ui-types";
import {FaArrowRight} from "react-icons/fa6";
import {useUserSettings} from "./UserSettingsContext";
import {FaCaretRight} from "react-icons/fa";

const AppHeader: React.FC = () => {
    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const {language, interfaceLanguage} = useUserSettings();

    const openSettings = () => setSettingsOpen(true);
    const closeSettings = () => setSettingsOpen(false);

    return (
        <header className="p-4 bg-gray-800 text-white flex justify-between">
            <h1 className="text-lg font-bold text-center">QUIZLY</h1>
            <div className="flex items-center space-x-2">
                <img
                    src={getFlagLink(language)}
                    alt="Language Flag"
                    className="w-8 h-8 object-contain" // You can adjust size here
                />
                <FaCaretRight className="text-lg text-white-600"/>
                <img
                    src={getFlagLink(interfaceLanguage)}
                    alt="Language Flag"
                    className="w-8 h-8 object-contain" // You can adjust size here
                />
            </div>
            <button
                onClick={openSettings}
                className="text-white hover:text-gray-300 flex items-center"
                aria-label="Settings"
            >
                <FiSettings className="w-6 h-6"/> {/* Icon with specified size */}
            </button>
            {isSettingsOpen && <SettingsModal onClose={closeSettings}/>}
        </header>
    );
};

export default AppHeader;