import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_GATEWAY} from "../App";
import {useUserSettings} from "./UserSettingsContext";

interface Props {
    word: string;
    isOpen: boolean;
    onClose: () => void;
    position: { x: number; y: number } | null;
}


const WordPopupModal: React.FC<Props> = ({word, isOpen, onClose, position}) => {
    const {language, interfaceLanguage} = useUserSettings();
    const [translation, setTranslation] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchTranslation = async () => {
            if (!word) {
                return;
            }
            setLoading(true);
            try {
                // Replace with your backend API call for translation
                const response = await axios.get(API_GATEWAY +
                    `/translate?word=${word}&language=${language}&interfaceLanguage=${interfaceLanguage}`);
                setTranslation(response.data.translation);
            } catch (error) {
                setTranslation("Translation not available");
            } finally {
                setLoading(false);
            }
        };
        fetchTranslation();
    }, [word]);

    if (!isOpen || !position) return null; // Don't render if not open or position is not set
    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            onClick={onClose} // Close modal on backdrop click
        >
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full md:w-1/2 lg:w-1/3 mx-4">
                    <h2 className="text-xl font-semibold mb-4">Translation</h2>
                    <p className="mb-4 text-gray-700">Original Word: <strong>{word}</strong></p>
                    {loading ? (
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-gray-500"></div>
                        </div>
                    ) : (
                        <p className="text-gray-900">Translation: <strong>{translation}</strong></p>
                        )}
                    <button
                        onClick={onClose}
                        className="mt-6 w-full py-2 bg-gray-500 text-white font-semibold rounded-lg hover:bg-gray-600 transition-colors"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WordPopupModal;
