import React, {useState} from 'react';
import WordPopupModal from "./WordPopupModal";

interface ExplanationDisplayProps {
    text: string;
}

const StoryText: React.FC<ExplanationDisplayProps> = ({text}) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalWord, setModalWord] = useState<string>('');
    const [modalPosition, setModalPosition] = useState<{ x: number; y: number } | null>(null);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const handleLongPressStart = (event: React.TouchEvent<HTMLSpanElement>, word: string) => {
        // Start the long press timer
        const newTimer = setTimeout(() => {
            const {clientX, clientY} = event.touches[0];
            handleLongPress(word, clientX, clientY); // Execute the long tap action
        }, 500);
        setTimer(newTimer);
    };

    const handleLongPressEnd = () => {
        // Clear the timer if the press ends early
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
    };

    const handleLongPress = (word: string, x: number, y: number) => {
        setModalWord(word);
        setModalPosition({x: x, y: y});
        setIsModalOpen(true);
    };

    const handleClick = (event: React.MouseEvent<HTMLSpanElement>, word: string) => {
        const clientX = event.clientX;
        const clientY = event.clientY;
        setModalWord(word);
        setModalPosition({x: clientX, y: clientY});
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalWord('');
        setModalPosition(null);
    };

    const renderContent = () => {
        const lines = text.split('\n') || [];
        return (<div className="w-full max-w-full overflow-hidden break-words">
            {lines.map((line, index) => (
                <div key={index} className="flex flex-wrap">
                    {line.split(" ").map((word, wordIndex) => (
                        <span
                            key={wordIndex}
                            // onTouchStart={(e) => e.preventDefault()} // Prevent default touch action
                            onTouchStart={(e) => handleLongPressStart(e, word)}
                            onTouchEnd={handleLongPressEnd}
                            onClick={(e) => handleClick(e, word)} // Handle click for long press
                            className="word cursor-pointer hover:bg-gray-200"
                            style={{margin: '0 2px'}} // Margin between words
                        >
                {word}
            </span>
                    ))}
                </div>
            ))}
        </div>);
    };

    return (
        <div className="flex items-center justify-center">
            <div className="w-full lg:w-1/2">
                <div className="flex flex-wrap gap-2">
                    {renderContent()}
                    <WordPopupModal
                        word={modalWord}
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        position={modalPosition}
                    />
                </div>
            </div>
        </div>
    );
};

export default StoryText;
