import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from "axios";
import {API_GATEWAY} from "../App";
import {UIStory} from "../ui-types/ui-types";
import StoryText from "../components/StoryText";

const StoryTab: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [story, setStory] = useState<UIStory | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const initialized = useRef(false);

    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true
        const fetchStoryContent = async () => {
            try {
                const response = await axios.get(API_GATEWAY + `/stories/${id}`);
                setStory(response.data.story);
            } catch (err) {
                setError('Failed to load story content');
            } finally {
                setLoading(false);
            }
        };
        fetchStoryContent();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>; // Loading state
    }

    if (error) {
        return <div>{error}</div>; // Error handling
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold text-center">{story?.name}</h1>
            <div className="mt-4 prose">
                {story && <StoryText text={story.text}/>}
            </div>
        </div>
    );

};

export default StoryTab;